import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

export type ContactUsFormResponse = {
    success: boolean;
    data: {
        body: string;
        customFields: [];
        domainId: number;
        email: string;
        firstName: string;
        lastName: string;
        id: number;
        phone: string;
        subject: string;
        userData: {
            ip: string;
            userAgent: string;
        };
        visitorId: string;
    } | null;
    code: "INVALID_PHONE_NUMBER_FORMAT" | undefined;
};

export const submitContactUsForm = async ({
    fields,
    type,
}: {
    fields: {
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        subject?: string | undefined;
        body: string;
        googleReCaptcha: string;
        customFields: { key: string; value: string }[];
    };
    type?: string;
}): Promise<{ data: ContactUsFormResponse | null; error: Error | null }> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/contact-us${type ? `?type=${type}` : ""}`,
        data: {
            ...fields,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: contactUsFormResponse, error } =
        await apiHandler<ContactUsFormResponse>(() => HTTP.client(config));

    return {
        data: contactUsFormResponse,
        error,
    };
};
